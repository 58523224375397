<template>
  <div class="py-1">
    <b-card no-body>
      <b-card-header>
        <div>
          <h4 class="font-weight-bolder">Create Assignment</h4>
          <p>You can create new lecture here for OXAR teacher.</p>
        </div>
        <div>
          <b-button
            type="submit"
            variant="colorLightBlue"
            rounded
            class="mr-1"
            @click="
              () => {
                this.$router.push({ name: 'InstructorDashboard' });
              }
            "
          >
            <span class="text-colorBlue">Cancel</span>
          </b-button>
          <b-button type="submit" @click="submit" variant="colorBlue" rounded>
            <span class="text-colorWhite">{{ getAssignmentData ? 'Update Assignment' : 'Create Assignment' }}</span>
          </b-button>
        </div>
      </b-card-header>
      <b-card-body>
        <hr />
        <b-form @submit.prevent="submit">
          <b-form-row class="py-1">
            <b-col>
              <span class="font-weight-bolder">Class Name*</span>
            </b-col>
            <b-col>
              <b-form-group class="mb-0">
                <v-select
                  label="name"
                  v-model="classe"
                  placeholder="Select Class"
                  :options="classes"
                  :reduce="option => option.id"
                  autoscroll
                  :clearable="true"
                ></v-select>
              </b-form-group>
            </b-col>
          </b-form-row>
          <hr />
          <b-form-row class="py-1">
            <b-col>
              <span class="font-weight-bolder">Subject*</span>
            </b-col>
            <b-col>
              <b-form-group class="mb-0">
                <v-select
                  label="name"
                  v-model="subject"
                  placeholder="Select Subject"
                  :options="subjects"
                  :reduce="option => option.id"
                  autoscroll
                  :clearable="true"
                ></v-select>
              </b-form-group>
            </b-col>
          </b-form-row>
          <hr />
          <b-form-row class="py-1">
            <b-col>
              <span class="font-weight-bolder">Assignment Title*</span>
            </b-col>
            <b-col>
              <b-form-group class="mb-0">
                <b-form-input
                  v-model="name"
                  id="assignmentTitle"
                  placeholder="Assignment Title"
                />
              </b-form-group>
            </b-col>
          </b-form-row>
          <hr />
          <b-form-row class="py-1">
            <b-col>
              <span class="font-weight-bolder">Assignment Deadline*</span>
            </b-col>
            <b-col>
              <b-form-group class="mb-0">
                <b-form-datepicker v-model="deadline" id="assignmentDeadline" />
              </b-form-group>
            </b-col>
          </b-form-row>
          <hr />
          <b-form-row class="py-1">
            <b-col>
              <span class="font-weight-bolder">Upload Assignment</span>
            </b-col>
            <b-col>
              <b-form-group class="mb-0">
                <vue-dropzone
                  ref="myVueDropzone"
                  id="dropzone"
                  :options="dropzoneOptions"
                  :useCustomSlot="true"
                  class="bg-colorLightBlue"
                  @vdropzone-file-added="handleFileAdded"
                >
                  <div class="dropzone-custom-content">
                    <b-img
                      :src="
                        require('@/assets/images/icons/instructor/assignment/upload.svg')
                      "
                    />
                    <h3
                      class="dropzone-custom-title text-colorBlue mt-1 font-weight-bolder"
                    >
                      Upload Multiple Files
                    </h3>
                    <div class="subtitle">
                      <p>Click browse through your machine</p>
                    </div>
                  </div>
                </vue-dropzone>
              </b-form-group>
            </b-col>
          </b-form-row>
          <hr />
          <b-form-row class="py-1">
            <b-col>
              <span class="font-weight-bolder">Assignment Type*</span>
            </b-col>
            <b-col>
              <b-form-group class="mb-0">
                <v-select
                  v-model="assignment_type"
                  :options="['Homework', 'Project']"
                  placeholder="Assignment Type"
                />
              </b-form-group>
            </b-col>
          </b-form-row>
          <hr />
          <b-form-group class="py-1 mb-0">
            <b-form-checkbox v-model="is_graded" id="graded-assignment" name="graded-assignment">
              <span class="font-weight-bolder">Graded Assignment</span>
            </b-form-checkbox>
          </b-form-group>
          <b-form-row class="py-1">
            <b-col>
              <span class="font-weight-bolder">Grading Method</span>
            </b-col>
            <b-col>
              <b-form-group class="mb-0">
                <v-select
                  v-model="grading_method"
                  :options="['Automatic', 'Manual']"
                  placeholder="Grading Method"
                />
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-group class="py-1">
            <span class="font-weight-bolder">Grading Criteria</span>
            <quill-editor v-model="grading_criteria" :options="editorOptions" class="mt-1" />
          </b-form-group>
        </b-form>
      </b-card-body>
      <b-card-footer footer-class="d-flex justify-content-end">
        <div>
          <b-button
            type="submit"
            variant="colorLightBlue"
            rounded
            class="mr-1"
            @click="
              () => {
                this.$router.push({ name: 'InstructorDashboard' });
              }
            "
          >
            <span class="text-colorBlue">Cancel</span>
          </b-button>
          <b-button type="submit" @click="submit" variant="colorBlue" rounded>
            <span class="text-colorWhite">{{ getAssignmentData ? 'Update Assignment' : 'Create Assignment' }}</span>
          </b-button>
        </div>
      </b-card-footer>
    </b-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { quillEditor } from "vue-quill-editor";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";

export default {
  components: {
    quillEditor,
    vueDropzone: vue2Dropzone,
  },
  
  data() {
    return {
      name: '',
      deadline: '',
      assignment_type: '',
      is_graded: false,
      grading_method: '',
      grading_criteria: '',
      classes: [],
      classe: '',
      subjects: [],
      subject: '',
      files: [],
      assignment: {},
      editorOptions: {
        // Add any desired quill-editor options here
      },
      dropzoneOptions: {
        url: "https://httpbin.org/post",
        thumbnailWidth: 150,
        maxFilesize: 0.5,
        headers: { "My-Awesome-Header": "header value" },
      },
    };
  },
  async mounted() {
    const res_classes = await this.getClassDropDown();
    this.classes = res_classes.data;

    const res_subject = await this.getSubjectDropDown();
    this.subjects = res_subject.data;
    if (this.getAssignmentData){
      this.classe = this.getAssignmentData.classes;
      this.subject = this.getAssignmentData.subject;
      this.name = this.getAssignmentData.name;
      this.deadline = this.getAssignmentData.deadline;
      this.assignment_type = this.getAssignmentData.assignment_type;
      this.is_graded = this.getAssignmentData.is_graded;
      this.grading_method = this.getAssignmentData.grading_method
      this.grading_criteria = this.getAssignmentData.grading_criteria;
    }
  },
  methods: {
    ...mapActions({
      getClassDropDown: "appData/getClassDropDown",
      getSubjectDropDown: "appData/getSubjectDropDown",
      createAssignment: "appData/createAssignment",
      updateAssignment: "appData/updateAssignment",
      clearAssignmentData: "appData/clearAssignmentData",
    }),

    handleFileAdded(file) {
      this.files.push(file);
    },

    async submit() {
      try {
        const formData = new FormData();
        formData.append('classes', this.classe);
        formData.append('subject', this.subject);
        formData.append('name', this.name);
        formData.append('deadline', this.deadline);
        formData.append('assignment_type', this.assignment_type);
        formData.append('is_graded', this.is_graded);
        formData.append('grading_method', this.grading_method);
        formData.append('grading_criteria', this.grading_criteria);

        this.files.forEach(file => {
          formData.append('new_files', file);
        });

        formData.append("created_by", this.getLoggedInUser.id);
        formData.append("updated_by", this.getLoggedInUser.id);

        if (this.getAssignmentData){
          const res = await this.updateAssignment({
            payload: formData, 
            pk: this.getAssignmentData.id
          });
          if (res.status === 200) {
            this.$swal({
              title: "Assignment updated successfully",
              icon: "success",
            });
            this.reset();
            this.$router.push({ name: 'instructorAssignment' });
          }
        }
        else{
          const res = await this.createAssignment(formData);
          if (res.status === 201) {
            this.$swal({
              title: "Assignment created successfully",
              icon: "success",
            });
            this.reset();
            this.$router.push({ name: 'instructorAssignment' });
          }
        }
      } catch (error) {
        this.displayError(error);
      }
    },

    reset() {
      this.name = '';
      this.deadline = '';
      this.assignment_type = '';
      this.is_graded = false;
      this.grading_method = '';
      this.grading_criteria = '';
      this.classe = '';
      this.subject = '';
      this.files = [];
      this.$refs.myVueDropzone.removeAllFiles();
      this.assignment = {};
    },
  },
  computed: {
    ...mapGetters({ getLoggedInUser: "appData/getLoggedInUser", getAssignmentData: "appData/getAssignmentData" }),
  },
  beforeRouteLeave(to, from, next) {
    this.clearAssignmentData();
    next();
  },
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/quill.scss";
</style>
